import React from "react"
import styled from "styled-components"
import { Wrapper } from "../components/style"
import get from "lodash.get"
import Slider from "react-slick"

export function GridSlideshow({ data }) {
  if (!data || !data.list) return null
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: data.list.length > 4,
    slidesToShow: data.list.length > 4 ? 4 : data.list.length,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          autoplay: data.list.length > 3 ? 3 : data.list.length,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          autoplay: data.list.length > 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <Wrapper>
      <Slider {...settings}>
        {data.list.map((block, i) => {
          return (
            <StyledBlock
              key={i}
              xs
              onClick={() =>
                block.link &&
                typeof window !== "undefined" &&
                window.open(block.link)
              }
            >
              <Lwrap>
                {" "}
                <Icon
                  src={block.image && block.image.publicURL}
                  style={{ cursor: block.link ? "pointer" : "default" }}
                />
                {block.label && <Label>{block.label}</Label>}{" "}
              </Lwrap>
              {block.name && (
                <h3
                  dangerouslySetInnerHTML={{
                    __html: block.name,
                  }}
                />
              )}
              {block.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: block.content,
                  }}
                />
              )}
              {block.link && block.button && (
                <a className={"button"} href={block.link}>
                  {block.button}
                </a>
              )}
            </StyledBlock>
          )
        })}
      </Slider>
    </Wrapper>
  )
}

const Lwrap = styled.div`
  && {
    text-align: right;
  }
`
const Label = styled.span`
  && {
    background: ${props => props.theme.color.secondary};
    color: ${props => props.theme.color.white};
    padding: 0.5em 0.8em;
    font-size: 0.65em;
    border-radius: 0.8em;
  }
`
const Icon = styled.div`
  && {
    height: 7rem;
    max-width:230px;
    margin:0 auto;
    background:url('${props => props.src}') center bottom no-repeat;
    background-size:contain;

  }
`

const StyledBlock = styled.div`
  && {
    padding: 2em;
    margin: 0 1em;
    h3 {
      font-size: 1.2em;
      margin: 0.5em 0;
      color: ${props => props.theme.color.primary};
    }
    > div {
      font-size: 0.8em;
      color: ${props => props.theme.color.primary};
    }
    &:hover {
      div,
      .button {
        display: block;
      }
    }
  }
`

/**
GridSlideshowBlock {
    list
      {
        name
        content
        link
        label
        image
      }
}
 */

export const GridSlideshowBlock = {
  label: "Grid Slideshow",
  name: "GridSlideshowBlock",
  key: "GridSlideshowBlock",
  defaultItem: {
    GridSlideshowBlock: {
      list: [
        {
          name: "",
          content: "",
          link: "",
          label: "",
          image: "..images/dummy.png",
        },
      ],
    },
  },
  fields: [
    {
      label: "Grid List",
      name: "GridSlideshowBlock.list",
      component: "group-list",
      description: "Grid List",
      itemProps: item => ({
        key: item.id,
        label: item.name,
      }),
      defaultItem: () => ({
        name: "New Content",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          label: "Title",
          name: "name",
          component: "text",
        },
        {
          label: "Content",
          name: "content",
          component: "html",
        },
        {
          label: "Link",
          name: "link",
          component: "text",
        },
        {
          label: "Label",
          name: "label",
          component: "text",
        },
        {
          label: "Image",
          name: "image",
          component: "image",
          parse: filename => `../images/${filename}`,
          uploadDir: () => `/content/images/`,
          previewSrc: (formValues, fieldProps) => {
            const pathName = fieldProps.input.name.replace(
              "rawJson",
              "jsonNode"
            )
            const imageNode = get(formValues, pathName)
            if (!imageNode) return ""
            return imageNode.publicURL
          },
        },
      ],
    },
    //...
  ],
}
