import TinaCMSFileField from "tinacms-file-field"
import TinaCMSConditionField from "tinacms-condition-field"

export function onServiceWorkerUpdateReady() {
  window.location.reload(true)
}

export const onClientEntry = () => {
  const fileField = new TinaCMSFileField(window.tinacms)
  fileField.install()

  const conditionField = new TinaCMSConditionField(window.tinacms)
  conditionField.install()
}
