import React from "react"
import { Wrapper } from "../style"
import styled, { css } from "styled-components"
import { transparentize } from "polished"
import { Nav } from "./nav"
import { ThemeContext } from "../Settings/theme"
import { Link } from "gatsby"
import Sticky from "react-stickynode"

export const Header = styled(
  ({ whitelogoBackground, logo, fixedlogo, siteTitle, ...styleProps }) => {
    const logoImage = logo && logo.publicURL
    const fixedLogoImage = fixedlogo && fixedlogo.publicURL

    const url = typeof window !== "undefined" ? window.location.href : ""
    let lang = "/"
    if (url.match(/\/en$/) || url.match(/\/en\//)) {
      lang = "/en"
    }
    return (
      <ThemeContext.Consumer>
        {({ theme }) => (
          <HeaderWrap fixedLogoImage={fixedLogoImage}>
            <Sticky
              enabled={true}
              top={0}
              className={`menus clearfix`}
              activeClass={"fixed"}
              bottomBoundary={"#footer"}
              innerZ={100}
            >
              <header {...styleProps}>
                <HeaderWrapper>
                  <SiteTitle>
                    <SiteLink to={lang}>
                      {logoImage ? (
                        <CompanyLogo
                          className={"logo"}
                          height={theme.header.height}
                          image={logoImage}
                        />
                      ) : (
                        siteTitle
                      )}
                    </SiteLink>
                  </SiteTitle>
                  <Nav />
                </HeaderWrapper>
              </header>
            </Sticky>
          </HeaderWrap>
        )}
      </ThemeContext.Consumer>
    )
  }
)`
  position: absolute;
  z-index: 100;
  width: 100%;
  height: ${props => props.theme.header.height};
  top: 0;
  background-color: ${props => props.theme.color.background};
  color: ${props => props.theme.color.foreground};
  transition: all 0.6s;

  ${props =>
    props.theme.header.overline &&
    css`
      border-top: 6px solid ${props => props.theme.color.primary};
    `};

  ${props =>
    props.theme.header.underline &&
    css`
      box-shadow: inset 0 -1px 0 ${props => transparentize(0.9, props.theme.color.white)},
        0 1px 0 ${props => transparentize(0.9, props.theme.color.black)};
    `};

  ${props =>
    props.theme.header.transparent &&
    css`
      background: ${props => props.theme.color.primary};
      background: linear-gradient(
        0deg,
        ${props => transparentize(1, props.theme.color.primary)}0%,
        ${props => props.theme.color.primary} 100%
      );

      color: ${props => props.theme.color.white};
    `};
`

export const HeaderWrap = styled.div`
.fixed {
  height:3em;
  header{
    height:4em;
    background:${props => transparentize(0.2, props.theme.color.primary)};
    .logo{
      height:2.5em;
      margin:0;
      background: url('${props => props.fixedLogoImage || ""}') no-repeat left;
      background-size: contain;
    }
  }
}`

export const SiteLink = styled(Link)`
  position: relative;
  line-height: 3rem;
  display: flex;
  align-items: center;
  align-self: stretch;
  color: inherit !important;
  text-decoration: none;
  margin: 0;
  transition: all 150ms ${p => p.theme.easing};
  z-index: 1;
`

export const CompanyLogo = styled.div`
  margin: 0;
  width:200px;
  margin-top: 2em;
  height: ${props => props.height};
  background: url('${props => props.image || ""}') no-repeat left;
  background-size: contain;
`

export const SiteTitle = styled.h1`
  margin: 0;
  flex: 0 0 auto;
  font-size: 1rem;
  align-self: stretch;
  display: flex;
`

export const HeaderWrapper = styled(Wrapper)`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`
