import React from "react"
import { Wrapper } from "../style"
import styled from "styled-components"
import { Row, Col } from "react-flexbox-grid"
import { GridSlideshow } from "../../blocks/gridSlideshow"
export const Footer = styled(({ data, ...styleProps }) => {
  return (
    <>
      <Wrapper>
        <Row>
          {data.footer.list && (
            <GridSlideshow data={{ list: data.footer.list }} />
          )}
        </Row>
      </Wrapper>

      <footer id={"footer"} {...styleProps}>
        <Wrapper>
          <Row>
            <Col md={4} className={"logo-footer"}></Col>
            <Col md={4}>
              <h3>{data.footer.title}</h3>
            </Col>
            <Col md={4}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.footer.Content,
                }}
              />
              <a
                href="https://twitter.com/migracorv?ref_src=twsrc%5Etfw"
                className={"twitter"}
                target={"_blank"}
              >
                <img
                  src={"/images/twitter-follow-button-png-8.png"}
                  width={"170px"}
                />
              </a>

              {data.footer.certs && (
                <img
                  src={data.footer.certs && data.footer.certs.publicURL}
                  alt={data.footer.title || ""}
                />
              )}
            </Col>
          </Row>
          <Row className="credits">
            <Col md={12}>
              © {new Date().getFullYear()} {data.title} – {`Developed with  `}
              <a
                href="https://cors.digital"
                target={"_blank"}
                rel="noopener noreferrer"
              >
                CORS Digital
              </a>
            </Col>
          </Row>
        </Wrapper>
      </footer>
    </>
  )
})`
  && {
    font-size: 0.8em;
    padding: 4em 0 1em 0;
    h3 {
      font-size: 2.2em;
    }
    color: ${props => props.theme.color.secondary};
    ${props =>
      props.data.footer.color
        ? `
        background-color: ${props.data.footer.color}; 
        color:${props.theme.color.white};
        a{ color:${props.theme.color.white}};
        .logo-footer{
            background: url('${props.data.footer.image &&
              props.data.footer.image.publicURL}')  center / contain no-repeat; 
        }`
        : ""};
    @media (max-width: ${props => props.theme.breakpoints.small}) {
      text-align: center;
    }
    .credits {
      font-size: 0.8em;
      opacity: 0.7;
      margin: 2em 0;
      text-align: center;
      ${props =>
        props.data.footer.color ? `a{ color:${props.theme.color.white}};` : ""};
    }
  }
`
