import React from "react"
import { Link } from "gatsby"

export const LinkHandle = props => {
  if (props.to && props.to.match(/http/)) {
    return (
      <a href={props.to} target={props.target}>
        {props.children}
      </a>
    )
  } else {
    return <Link {...props}>{props.children}</Link>
  }
}
