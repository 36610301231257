module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/usr/src/app/node_modules/gatsby-tinacms-git","id":"8588a462-6421-5dc1-b95c-a0853c5b54b5","name":"gatsby-tinacms-git","version":"0.3.0","pluginOptions":{"plugins":[],"defaultCommitMessage":"Edited with TinaCMS  - [skip ci] - ","gitRemote":"git@bitbucket.org:cors-digital/migracorv.git","defaultCommitName":"TinaCMS","defaultCommitEmail":"git@tinacms.org","pushOnCommit":true},"nodeAPIs":["onCreateDevServer"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]},{"resolve":"/usr/src/app/node_modules/gatsby-tinacms-json","id":"acc053f9-4f17-5fb2-9948-4bd93063a73c","name":"gatsby-tinacms-json","version":"0.8.20","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/usr/src/app/node_modules/gatsby-tinacms-remark","id":"4e049a88-2f70-5135-883f-87a76d01585d","name":"gatsby-tinacms-remark","version":"0.7.15","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":[],"ssrAPIs":[]}],"sidebar":{"hidden":true,"position":"displace","theme":{"color":{"primary":{"light":"#114F68","medium":"#114F68","dark":"#114F68"}}}}},
    },{
      plugin: require('../node_modules/gatsby-tinacms-git/gatsby-browser.js'),
      options: {"plugins":[],"defaultCommitMessage":"Edited with TinaCMS  - [skip ci] - ","gitRemote":"git@bitbucket.org:cors-digital/migracorv.git","defaultCommitName":"TinaCMS","defaultCommitEmail":"git@tinacms.org","pushOnCommit":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/usr/src/app/src/components/siteLayout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MIGRACORV","short_name":"MIGRACORV","start_url":"/","background_color":"#114F68","theme_color":"#114F68","display":"minimal-ui","icon":"content/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:700","Roboto:400,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":1,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
