import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { Header } from "./Structure/header"
import { Footer } from "./Structure/footer"
import { Theme } from "./Settings/theme"
import Helmet from "react-helmet"
import slugify from "react-slugify"

import { JsonCreatorPlugin } from "gatsby-tinacms-json"
import { withPlugin } from "tinacms"

const MasterLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query MasterLayoutQuery {
      site: settingsJson(
        fileRelativePath: { eq: "/content/settings/site.json" }
      ) {
        title
        fixedlogo {
          extension
          publicURL
        }
        logo {
          extension
          publicURL
        }
        footer {
          title
          color
          Content
          image {
            extension
            publicURL
          }
          list {
            label
            image {
              publicURL
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/focus-visible@5.0.2/dist/focus-visible.min.js"></script>
      </Helmet>
      <Theme>
        <Header
          logo={data.site.logo}
          fixedlogo={data.site.fixedlogo}
          siteTitle={data.site.title}
        />
        <Site>
          {children}
          <Footer data={data.site} />
        </Site>
      </Theme>
    </>
  )
}

const CreatePageButton = new JsonCreatorPlugin({
  label: "New Page",
  filename(form) {
    let slug = slugify(form.title.toLowerCase())
    return `content/pages/${slug}.json`
  },
  fields: [
    { name: "title", label: "Title", component: "text", required: true },
    { name: "path", label: "Path", component: "text", required: true },
  ],
  data(form) {
    return {
      title: form.title,
      path: form.path,
      blocks: [
        {
          _template: "TitleBlock",
          title: "",
          underline: true,
          content: "",
        },
        {
          _template: "ContentBlock",
          content:
            "## Umor bis\n\nAdit suo ait in olorinis portitor **undam tangentia** quibus Hectora invideatis cardine pressit fovet, _fuit_; nec barba sudantibus? Forte aliquis, ultaque et is inritamen velamina Clarium: [non](http://retiaque-in.io/). Inter pendentia eligit, sua fugam cumque. Domat ex nomina rogantum genitas delapsam: iam quae capillos facienda lanificae tectus solidorum. Seraque Medea, et exstinctique et conata **Cumarum** species ultra re cognita!\n\n## Nec qui esse indignatus rursus meo labor\n\nEt offensane vocato, vivebat Acrisioniades cinisque antiquus furit corripiens. Ab quoque, cum ponit circuitu resuscitat timor exspiravit corpore. Erat tenus et nocet, pervenit coniuge fibra feris sequentis pares membrana illa. Praelatus tumultus flores annos. Fidumque Acoetes.\n\n1. Vim remis\n2. Non haerebat teneas raptaturque marito huius\n3. Nomen carinas placato mora deprensi umbras\n4. Iole utque quoque\n5. Aedibus totumque timuere senex Argentea corpore Et tanti ficti _non_ removente lenimen _Crete vestigia_ in deus, Olenios velut. Agna fulget, gratia habitant fonti pignus. Cura modo sacra petatur non adligat placebant sic discubuere tamen caelestibus pendebat.",
        },
      ],
    }
  },
})

export default withPlugin(MasterLayout, [CreatePageButton])

export const Site = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > ${Header} {
    flex: 0 0 auto;
  }

  > ${Footer} {
    flex: 0 0 auto;
  }

  > * {
    flex: 1 0 auto;
  }

  ${props =>
    props.theme.hero.parallax &&
    css`
      overflow-y: auto;
      /* overflow-x: hidden; */
      perspective: 1px;
      perspective-origin: top;
      /* scrollbar-width: none; */
      /* -ms-overflow-style: none; */

      ::-webkit-scrollbar {
        /* display: none; */
      }
    `}
`
