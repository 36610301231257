import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { mix, transparentize } from "polished"
import { LinkHandle } from "../LinkHandle"
export const Nav = props => {
  const data = useStaticQuery(graphql`
    query navQuery {
      settingsJson(fileRelativePath: { eq: "/content/settings/menu.json" }) {
        ...nav
        menuItems {
          label
          labelEN
          link
          subMenu {
            labelEN
            label
            link
          }
        }
      }
    }
  `)

  const [navOpen, setNavOpen] = useState(false)
  const toggleNavOpen = () => {
    setNavOpen(!navOpen)
  }

  const menu = data.settingsJson

  const url = typeof window !== "undefined" ? window.location.href : ""
  let lang = ""
  if (url.match(/\/en$/) || url.match(/\/en\//)) {
    lang = "/en"
  }
  return (
    <>
      <StyledNavbar navopen={navOpen}>
        {menu.menuItems.map((item, i) => {
          return (
            <NavItem key={i}>
              <NavLink
                onClick={toggleNavOpen}
                partiallyActive={item.link === "/" ? false : true}
                to={`${lang}${item.link}`}
              >
                {lang === "/en" ? item.labelEN : item.label}
              </NavLink>

              {item.subMenu && (
                <NavSubMenu>
                  {item.subMenu.map((sub, ind) => {
                    return (
                      sub.label && (
                        <li key={ind}>
                          <NavLink
                            onClick={toggleNavOpen}
                            partiallyActive={sub.link === "/" ? false : true}
                            to={`${lang}${sub.link}`}
                          >
                            {lang === "/en" ? sub.labelEN : sub.label}
                          </NavLink>
                        </li>
                      )
                    )
                  })}
                </NavSubMenu>
              )}
            </NavItem>
          )
        })}
        <Lang>
          <a href="/en" className={lang === "/en" ? "active" : ""}>
            EN
          </a>
          |{" "}
          <a href="/" className={lang !== "/en" ? "active" : ""}>
            PT
          </a>
        </Lang>
      </StyledNavbar>
      <NavToggle
        aria-label="Toggle Nav"
        onClick={toggleNavOpen}
        navopen={navOpen ? "true" : "false"}
      ></NavToggle>
    </>
  )
}

export const StyledNavbar = styled.ul`
  color: inherit;
  @media (max-width: 840px) {
    font-size:.8em;
  }
  @media (max-width: calc(${props => props.theme.breakpoints.small} - 1px)) {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 100%, 0);
    width: 100%;
    font-size:1em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 0;
    z-index: 1000;
    background-color: ${props =>
      props.theme.header.transparent
        ? mix(0.95, props.theme.color.primary, props.theme.color.white)
        : mix(0.95, props.theme.color.white, props.theme.color.primary)};
    box-shadow: 0 1rem 2rem -0.5rem ${props =>
      transparentize(0.5, props.theme.color.white)};
    transition: all 150ms ${p => p.theme.easing};
    pointer-events: none;
    ${props =>
      props.navopen &&
      css`
        opacity: 1;
        pointer-events: all;
      `};
      a{
        font-size: 1em;
        line-height: 5em;
      }
  }

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    align-items: stretch;
    justify-content: flex-end;
    flex: 1 0 auto;
    margin: 0;
    opacity: 1;
    pointer-events: all;
  }
`

export const MenuItem = {
  name: "menuItem",
  key: "label",
  label: "Menu Item",
  component: "group",
  fields: [
    { name: "label", label: "Label", component: "text" },
    { name: "link", label: "Path", component: "text" },
  ],
}

export const MenuForm = {
  label: "Menu",
  fields: [
    {
      label: "Menu Items",
      name: "rawJson.menuItems",
      component: "blocks",
      templates: {
        MenuItem,
      },
    },
  ],
}

export const Lang = styled.div`
  && {
    position: fixed;
    z-index: 999;
    right: 1em;
    font-size: 0.6em;
    background: #3ac3d5;
    padding: 0.5em 0.8em;
    border-radius: 7px;
    margin-top: 0.4em;
    color: #114f68;
    a {
      padding: 0.2em 0.8em;
      color: #fff !important;
      text-decoration: none;
    }
    .active {
      color: #114f68 !important;
    }
  }
`

export const NavSubMenu = styled.ul`
  && {
    position: absolute;
    /* width: 200px; */
    display: none;
    background: ${props => transparentize(0.25, props.theme.color.secondary)};
    padding: 0;
    margin: 0;
    left: 0;
    top: ${props => props.theme.header.height};
    li {
      padding: 1em 0.5em;
      margin-bottom: 0 !important;
      border-bottom: 1px solid
        ${props => transparentize(0.85, props.theme.color.white)};
      &:last-child {
        border-bottom: none;
      }
      a {
        font-size: 0.8em;
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }
`

export const NavItem = styled.li`
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  position: relative;
  color: inherit;
  &:hover {
    ul {
      display: block;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.small}) {
    &:not(:last-child) {
      border-bottom: 1px solid
        ${props => transparentize(0.85, props.theme.color.white)};
    }
  }
`

export const NavLink = styled(({ children, ...styleProps }) => (
  <LinkHandle activeClassName="active" {...styleProps}>
    <span>{children}</span>
  </LinkHandle>
))`
  flex: 1 0 auto;
  line-height: ${props => props.theme.header.height};
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.7em;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: inherit !important;
  opacity: 0.8;
  overflow: visible;
  transition: all 150ms ${p => p.theme.easing};
  z-index: 1;

  &:focus-visible {
    opacity: 1;
  }

  &:hover:not(.active) {
  }

  &:hover,
  &:active,
  &.active {
    opacity: 1;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  }

  &.active {
  }

  span {
    display: block;
    width: 100%;
  }

  ${props =>
    props.theme.menu.style === "pill" &&
    css`
      padding: 0 1rem;

      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0rem;
        left: 0rem;
        right: 0rem;
        bottom: 0rem;
        opacity: 0;
        z-index: -1;
        background-color: ${props =>
          props.theme.header.transparent
            ? props.theme.color.background
            : transparentize(0.95, props.theme.color.foreground)};
        border: 1px solid
          ${props => transparentize(0.95, props.theme.color.foreground)};
        transition: all 150ms ${props => props.theme.easing};
      }

      &:focus-visible {
        opacity: 1;
        &:before {
          opacity: 0.15;
        }
      }

      &:hover:not(.active) {
        &:before {
          opacity: 0.15;
        }
      }

      &:hover,
      &:active,
      &.active {
        opacity: 1;

        &:before {
          opacity: 1;
        }
      }

      &.active {
        color: ${props => props.theme.color.primary} !important;
        &:before {
          opacity: 1;
        }
      }

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        &:before {
          top: 0.625rem;
          left: 0.25rem;
          right: 0.25rem;
          bottom: 0.625rem;
          border-radius: 3rem;
        }
      }
    `}

  ${props =>
    props.theme.menu.style === "glow" &&
    css`
      &:after {
        content: "";
        display: none;
        position: absolute;
        top: -6px;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: ${props =>
          transparentize(0.85, props.theme.color.white)};
        transform: translate3d(0, -100%, 0);
        transition: all 150ms ${props => props.theme.easing};
      }

      &:before {
        content: "";
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          ${props =>
            transparentize(
              0.75,
              mix(0.25, props.theme.color.white, props.theme.color.white)
            )},
          transparent 1.5rem
        );
        opacity: 0;
        z-index: -1;
        transform: translate3d(0, -100%, 0);
        transition: all 150ms ${props => props.theme.easing};
      }

      &:focus-visible {
        opacity: 1;
        &:before {
          transform: translate3d(0, 0, 0);
          opacity: 0.5;
        }
      }

      &:hover:not(.active) {
        &:before {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
        &:after {
          background-color: ${props =>
            transparentize(0.8, props.theme.color.black)};
        }
      }

      &:hover,
      &:active,
      &.active {
        opacity: 1;

        &:before {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
        &:after {
          transform: translate3d(0, 0, 0);
        }
      }

      &.active {
        &:before {
          background: linear-gradient(
            to bottom,
            ${props =>
              transparentize(
                0.75,
                mix(0.5, props.theme.color.white, props.theme.color.black)
              )},
            transparent 1.5rem
          );
        }
      }

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        &:after,
        &:before {
          display: block;
        }
      }
    `}
`

export const NavToggle = styled(({ menuOpen, ...styleProps }) => {
  return (
    <button {...styleProps}>
      <div></div>
      <div></div>
      <div></div>
    </button>
  )
})`
  position: relative;
  padding: 0;
  border: 0;
  background: transparent;
  color: inherit;
  cursor: pointer;
  margin-left: 1rem;
  font-size: 0.8rem;
  line-height: 1;
  align-self: stretch;
  text-transform: uppercase;
  color: inherit;
  opacity: 0.5;
  overflow: visible;
  transition: all 150ms ${p => p.theme.easing};

  .open {
    display: none;
  }
  .closed {
    display: block;
  }

  &:focus {
    opacity: 1;
    text-decoration: underline;
  }

  &:hover {
    opacity: 1;
  }

  div {
    width: 35px;
    height: 3px;
    background-color: ${props => props.theme.color.white};
    margin: 6px 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    display: none;
  }

  ${props =>
    props.navopen &&
    css`
      .open {
        display: block;
      }
      .closed {
        display: none;
      }
    `};
`

export const navFragment = graphql`
  fragment nav on SettingsJson {
    menuItems {
      link
      label
    }
  }
`

export const NavForm = {
  label: "Menu",
  fields: [
    {
      label: "Main Menu",
      name: "rawJson.menuItems",
      component: "group-list",
      itemProps: item => ({
        label: item.label,
      }),
      fields: [
        {
          label: "Label",
          name: "label",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "LabelEN",
          name: "labelEN",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Link",
          name: "link",
          component: "text",
          parse(value) {
            return value || ""
          },
        },
        {
          label: "Sub Menu",
          name: "subMenu",
          component: "group-list",
          itemProps: item => ({
            key: item.link,
            label: item.label,
          }),
          fields: [
            {
              label: "Label",
              name: "label",
              component: "text",
            },
            {
              label: "LabelEN",
              name: "labelEN",
              component: "text",
            },
            {
              label: "Link",
              name: "link",
              component: "text",
            },
            {
              label: "Sub Menu",
              name: "subMenu",
              component: "group-list",
              itemProps: item => ({
                key: item.link,
                label: item.label,
              }),
              fields: [
                {
                  label: "Label",
                  name: "label",
                  component: "text",
                },
                {
                  label: "Link",
                  name: "link",
                  component: "text",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
